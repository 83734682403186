import React, { Component } from "react";
import { Zoom } from "react-reveal";
import LazyImage from "../common/lazyImage";
export default class SliderImageComponent extends Component {
    render() {
        return (
            <Zoom>
                <li
                    id={`myDiv`}
                    className={` ${this.props.id % 2 === 0 ? "inverted" : ""} ${
                        // this.props[`isActive${this.props.scrollvalue}`] !==
                        // undefined
                        //     ? this.props[`isActive${this.props.scrollvalue}`]
                        //     : ""
                        this.props.scrollvalue === this.props.currentScrrol
                            ? "custom_active"
                            : ""
                    }`}
                >
                    <div className="b-timeline__list__badge"></div>
                    <div className="b-timeline__list__panel">
                        <div className="b-polygon-badge">
                            <LazyImage
                                src={require("../../../static/assets/svg/achivement/hexagone.svg")}
                                alt=""
                            />
                            <span className="b-polygon-badge__text">
                                {this.props.data.year}
                            </span>
                        </div>
                        <div className="b-timeline__list__panel__img">
                            <LazyImage src={this.props.data.image} alt="" />
                        </div>
                        <div className="b-timeline__list__panel__text">
                            <p>{this.props.data.description}</p>
                        </div>
                        <div className="b-angle"></div>
                    </div>
                </li>
            </Zoom>
        );
    }
}
