import React from "react";
import Achievement from "../components/Achievement"
import {graphql} from "gatsby";
import SEO from "../components/SEO";

const HKAchievements = (props) => {
    return <React.Fragment>
        <SEO {...(props?.data?.seo?.frontmatter || {})}/>
        <Achievement {...props} />
    </React.Fragment>
};
export default HKAchievements;
export const query = graphql`
  query AchivementsQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "achievement-seo"}}) {
        frontmatter {
            title
            description
        }
    }
 }
`;